
.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.placeholder-white::placeholder {
  color: #E2E8F0
}

@keyframes pulse {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
}

.a-modal {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.a-dialog {
  border-radius: 8px !important;
  padding: 8px 12px !important;
}

.a-dialog-text {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.8em !important;
}

.a-dialog-buttons-container .a-dialog-button {
  font-size: 18px !important;
  font-weight: 600 !important;
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-top-width: 0;
  border-top-style: solid;
  border-radius: 6px;
  border-color: var(--chakra-colors-gray-400);
  filter: 'drop-shadow(0px 10px 7px rgba(17,24,55,.21))';
}

.a-dialog-button.a-dialog-allow-button {
  background-color: var(--chakra-colors-primary-500);
  border-color: var(--chakra-colors-primary-600);
  color: white;

}

.a-dialog-button.a-dialog-deny-button {
  background-color: var(--chakra-colors-gray-100);
}

.a-dialog-ok-button {
  background-color: green !important;
  border-radius: 6px !important;
}